<script>
import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LMarker, LIcon} from "@vue-leaflet/vue-leaflet";
import Icon from "@/components/icon.vue";
const imageUrl = new URL('@/assets/svg/marker-map.svg', import.meta.url).href
export default {
  name: "area-map",
    props:{
      area:{
          default:()=>{}
      }
    },
  components: {
    Icon,
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
    created() {
      this.marker = this.center =  [this.area.lat,this.area.lng]
    },
    data() {
    return {
      map: null,
      minZoom: 6,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      url: 'https://tiles.openstreetmap.org.ua/tile/{z}/{x}/{y}.png',
      zoom: 12,
      center: [],
      marker: [],
      iconUrl: imageUrl
    }
  },
  methods: {
    readyHandler(map) {
      this.map = map;
    },
    plusZoom() {
      this.zoom++
    },
    minusZoom() {
      this.zoom--
    },
  }
}
</script>

<template>
  <div class="map__wrapper">
    <l-map ref="map" v-model:zoom="zoom" class="leaflet__map"
           v-model:center="center"
           v-model:attribution="attribution"
           :useGlobalLeaflet="false"
           :minZoom="minZoom"
           @ready="readyHandler"
    >
      <l-tile-layer
          :url="url"
          :attribution='attribution'
      ></l-tile-layer>
      <l-marker :lat-lng="marker">
        <l-icon
            :icon-url="iconUrl"
            :iconSize="[30,30]"
        >
        </l-icon>
      </l-marker>
    </l-map>
    <div class="map__control">
      <a href="" @click.prevent="plusZoom" class="plus__btn btn control__btn">
        <icon name="plus"/>
      </a>
      <a href="" @click.prevent="minusZoom" class="minus__btn btn control__btn">
        <icon name="minus"/>
      </a>
    </div>
  </div>

</template>

<style lang="scss">
.leaflet__map {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;

  .leaflet-control-container {
    display: none;
  }

  .leaflet-div-icon {
    background-color: transparent;
    border: none;
  }

}

.map__wrapper {
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  .zoom__btn {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(36, 36, 36, 0.20);
    position: absolute;
    top: 30px;
    z-index: 5;
    right: 30px;
    background-color: white;
  }

  .map__control {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 5;
    display: flex;
    flex-direction: column;

    .btn {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info__btn {
      background-color: $black;
      color: white !important;
      margin-bottom: 20px;
    }

    .control__btn {
      background-color: white;
      color: #333333 !important;
      margin-bottom: 5px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .map__wrapper {
    .zoom__btn {
      top: 15px;
      right: 15px;
    }

    .map__control {
      left: 15px;
      bottom: 15px;
    }
  }
}


</style>
