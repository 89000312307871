<script>
import VueMultiselect from "vue-multiselect";
import axios from "axios";
import {vMaska} from "maska";
import {isArray} from "uikit/src/js/util/index.js";

export default {
    name: "phone-input",
    directives: {maska: vMaska},
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            countries: [],
            country: {
                "name": "Ukraine",
                "code": "+380",
                "iso": "UA",
                "flag": "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
                "mask": "(##)###-##-##"
            },
        }
    },
    components: {
        VueMultiselect
    },
    created() {
        this.getMasks();
    },
    methods: {
        isArray,
        resetPhone(){
            this.phone = ''
        },
        async getMasks() {
            try {
                const res = await axios.get('/countries.json')
                this.countries = res.data;
            } catch (e) {
                console.log(e)
            }
        }
    },

    computed: {
        placeholder(){
            let i =0;
            let pl = '';
            let mask = isArray(this.country.mask)?this.country.mask[0]:this.country.mask;
            while (i < mask.length) {
              pl += mask[i].replace('#','_')
                i++;
            }
            return pl
        },
        phone: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>

<template>
    <div class="phone__input-wrapper">
        <VueMultiselect
            class="multiselect__component"
            v-model="country"
            :options="countries"
            :multiple="false"
            :searchable="true"
            label="name"
            track-by="name"
            placeholder=""
            :showLabels="false"
            @select="resetPhone"
        >
            <template #option="{option}">
                <div class="multiselect__option-custom" :class="{'is_ua':option.iso=='UA'}">
                    <img width="20" height="10" :src="option.flag" alt="">

                    <span class="title">
                        {{ option.name }}
                    </span>
                </div>
            </template>
            <template #noResult>Нічого не знайдено</template>
            <template #placeholder>----</template>
            <template #singleLabel="{option}">
                <span><img :src="option.flag" width="20" height="10" alt=""></span>
            </template>
        </VueMultiselect>
        <input type="tel"
               v-maska
               :data-maska="`${country.code}${isArray(country.mask)?country.mask[0]:country.mask}`"
               v-model="phone"
               :placeholder="`${country.code}${placeholder}`"
               class="input">
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
@import '../../scss/components/multiselect-styles';

.phone__input-wrapper {
    display: grid;
    grid-template-columns: 90px 1fr;

    .multiselect.multiselect__component .multiselect__option-custom {
        white-space: normal;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .multiselect__element:has(.is_ua){
        position: sticky;
        top: 0;
        background-color: #F6F6F6;
        z-index: 3;
    }

    .multiselect .multiselect__content-wrapper {
        width: 200px;
    }

    .multiselect.multiselect__component .multiselect__tags {
        height: 65px;
        border-radius: 10px 0 0 10px;
        display: flex;
        align-items: center;
    }

    .input {
        border-radius: 0 10px 10px 0 !important;
        padding-left: 0!important;
    }

    .multiselect.multiselect__component .multiselect__select{
        top: 22px;
    }
}
</style>
