<script>
export default {
    name: "server-errors",
    props: {
        errors: {
            type: Object,
            required: true
        }
    }
}
</script>

<template>
        <div class="valid__error" v-for="err in errors">
            {{err[0]}}
        </div>
</template>

<style scoped lang="scss">
.valid__error {
    color: red;
    font-size: 12px;
    padding: 5px 0;
}
</style>
