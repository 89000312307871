<script>
import Icon from "@/components/icon.vue";
import UIkit from "uikit";
const image = new URL('@/assets/images/request-sended.png', import.meta.url).href
export default {
    name: "request-success",
    components: {Icon},
    data(){
        return{
            image: image
        }

    },
    mounted() {
        this.$mitt.on('request-success', this.showModal)
    },
    methods:{
        showModal(item) {
            UIkit.modal(this.$refs.request_success).show();
        },
        hideModal() {
            UIkit.modal(this.$refs.request_success).hide();
        }
    }
}
</script>

<template>
    <div  class="uk-flex-top offer__price-modal message__modal" uk-modal ref="request_success">
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button class="uk-modal-close-outside" type="button">
                <Icon name="close" class-name="icon__svg"/>
            </button>
            <div class="modal__content">
                <div class="modal__image-wrapepr">
                    <img :src="image" alt="" loading="lazy" width="120" height="90">
                </div>
                <div class="modal__title">
                    Дякуємо, заявку подано!
                </div>
                <div class="modal__subtitle">
                    Найближчим часом наші менеджери оброблять інформацію, щодо даної заявки. Слідкуйте за статусом Вашої заявки та вкладкою сповіщень. Подивитись актуальний статус заявки можна тут.
                </div>
                <form  class="offer__price-form">
                    <div class="form__btn">
                        <button @click.prevent="hideModal" class="submit__btn" >
                            Подати ще заявку
                        </button>
                        <a href="/profile" class="to__requests-btn">Перейти в Мої заявки</a>
                    </div>
                </form>
            </div>


        </div>
    </div>
</template>

<style scoped lang="scss">
@import '../scss/components/moda-styles';
.form__btn{
    gap: 10px;
    .to__requests-btn{
        @include green-btn(65px, 280px, 12px, 50px)
    }
}
@media (max-width: $breakpoint-md) {
    .form__btn{
        flex-direction: column;
        .to__requests-btn{
            width: 100%;
        }
    }

}
</style>

