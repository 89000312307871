<script>
export default {
    name: "error-field",
    props: {
        errors: '',
        type: Proxy
    }
}
</script>

<template>
    <template v-for="error in errors">
<!--        {{errors}}-->
        <template v-if="error.$params" v-for="er in error.$params">
           <span class="valid__error" v-if="er == 'required'">
               Обов'язкове поле
            </span>
            <span class="valid__error" v-else-if="er == 'requiredIf'">
               Обов'язкове поле
            </span>
            <span class="valid__error" v-else-if="er == 'sameAs'">
                {{ error.$propertyPath == 'data_check' ? 'Обов\'язкове поле' : 'Паролі не співпадають' }}

            </span>
            <span class="valid__error" v-else-if="er == 'email'">
               Некоректний E-mail
            </span>
            <span class="valid__error" v-else-if="er == 'tel'">
               Некоректний номер телефону
            </span>
            <span class="valid__error" v-else-if="er == 'minLength'">
               Мінімум {{error.$params['min']}} символ(ів)
            </span>
            <span class="valid__error" v-else-if="er == 'numeric'">
               Введіть число
            </span>
            <span class="valid__error" v-else-if="er == 'inn'">
              Некоректний ІПН
            </span>
        </template>
    </template>
</template>

<style lang="scss">
.valid__error {
    color: red;
    font-size: 12px;
    padding: 5px 0;
}
</style>
