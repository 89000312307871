<script>
import UIkit from "uikit";
import Icon from "@/components/icon.vue";
import {useVuelidate} from '@vuelidate/core'
import {required, email, numeric} from '@vuelidate/validators'
import ErrorField from "@/components/error-field.vue";
import axios from "axios";

const image = new URL('@/assets/images/offer-price.png', import.meta.url).href

export default {
  name: "offer-price-modal",
  components: {Icon, ErrorField},
  setup: () => ({v$: useVuelidate()}),
    props:['id'],
  data() {
    return {
      price: null,
      image: image,
      _id:null,
    }
  },

  mounted() {
    this.$mitt.on('offer-price', this.showModal)
  },
  validations() {
    return {
      price: {required, numeric},

    }
  },
  methods: {
    showModal(id) {
        console.log(id)
        this._id = id;
      UIkit.modal(this.$refs.offer__price).show();
    },
    async submit() {
      const result = await this.v$.$validate()
      if (!result) {
        // notify user form is invalid
        return;
      }
      try {
        const res = await axios.post(this.lroute('area.request'), {id:this._id, price:this.price}); //
        // await this.$nextTick(() => this.v$.reset())
          this.price = ''
          await this.$mitt.emit('request-success')
      } catch (err) {
        throw Error(err.message)
      }
      // perform async actions
    }
  }
}
</script>

<template>
  <div id="offer_price" ref="offer__price" class="uk-flex-top offer__price-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

      <button class="uk-modal-close-outside" type="button">
        <Icon name="close"/>
      </button>
      <div class="modal__content">
        <div class="modal__image-wrapepr">
          <img :src="image" alt="" loading="lazy" width="120" height="86">
        </div>
        <div class="modal__title">
          Запропонуйте ціну по якій ви готові придбати цю ділянку
        </div>
        <form @submit.prevent="submit" class="offer__price-form">
          <div class="input__wrapper" :class="{'invalid' : v$.price.$errors.length}">
            <input type="number" class="input" v-model.trim="price" placeholder="Ціна">
            <error-field :errors="v$.price.$errors"></error-field>
          </div>
          <div class="form__btn">
            <button class="submit__btn" @click.prevent="submit">
                Надіслати
            </button>
          </div>
        </form>
      </div>


    </div>
  </div>
</template>
<style scoped lang="scss">
@import '../scss/components/moda-styles';
</style>
